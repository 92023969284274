<template>
  <div
    ref="reportContainer"
    class="report-container"
  />
</template>

<script>
import * as pbi from 'powerbi-client';

export default {
  props: {
    embedConfig: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.embedReport();
  },
  methods: {
    embedReport() {
      const permissions = pbi.models.Permissions.All;
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        permissions: permissions,
        id: this.$props.embedConfig.reportId,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.$props.embedConfig.reportId}&groupId=${this.$props.embedConfig.groupId}`,
        accessToken: this.$props.embedConfig.accessToken
      };

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const reportContainer = this.$refs.reportContainer;
      powerbi.embed(reportContainer, config);
    }
  }
};
</script>

<style>
.report-container {
  width: 100%;
  height: 889px;
}

@media (max-width: 1919px) {
  .report-container {
    height: 750px;
  }
}

@media (max-width: 1659px) {
  .report-container {
    height: 493px;
  }
}

@media (max-width: 1199px) {
  .report-container {
    height: 523px;
  }
}

@media (max-width: 992px) {
  .report-container {
    height: 397px;
  }
}

@media (max-width: 768px) {
  .report-container {
    height: 289px;
  }
}

@media (max-width: 576px) {
  .report-container {
    height: 230px;
  }
}

@media (max-width: 450px) {
  .report-container {
    height: 191px;
  }
}

@media (max-width: 380px) {
  .report-container {
    height: 156px;
  }
}
</style>
